<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        @click="$router.push({name:'home'})"
      >
        <color-logo />
        <h2 class="brand-text text-primary ml-1">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('Welcome to') }} {{ appName }}! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Please sign-in to your account and start the adventure') }}
          </b-card-text>
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              :label="$t('Email')"
              label-for="login-email"
            >
              <b-form-input
                id="login-email"
                v-model="email"
                :state="ValidationEmail?false:null"
                name="login-email"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ ValidationEmail }}</small>
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{ $t('Password') }}</label>
                <b-link :to="{name:'auth-forgot-password-v2'}">
                  <small>{{ $t('Forgot Password?') }}</small>
                </b-link>
              </div>

              <b-input-group
                class="input-group-merge"
                :class="ValidationPassword ? 'is-invalid':null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="ValidationPassword?false:null"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ ValidationPassword }}</small>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                {{ $t('Remember Me') }}
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
              @click="Login"
            >
              {{ $t('Sign in') }}
            </b-button>
          </b-form>
          <b-card-text
            v-if="EnvRegister"
            class="text-center mt-2"
          >
            <span>{{ $t('New on our platform?') }} </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;{{ $t('Create an account') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('or') }}
            </div>
          </div>

          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center social">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
              @click="social_login('facebook')"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
              @click="social_login('google')"
            >
              <font-awesome-icon :icon="['fab', 'google']" />
            </b-button>
            <b-button
              variant="microsoft"
              href="javascript:void(0)"
            >
              <font-awesome-icon :icon="['fab', 'microsoft']" />
            </b-button>
            <b-button
              variant="linkedin"
              href="javascript:void(0)"
              @click="social_login('linkedin')"
            >
              <font-awesome-icon :icon="['fab', 'linkedin']" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
              @click="social_login('github')"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
            <b-button
              variant="warning"
              href="javascript:void(0)"
              @click="social_login('gitlab')"
            >
              <font-awesome-icon :icon="['fab', 'gitlab']" />
            </b-button>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import { $themeConfig } from '@themeConfig'
import ColorLogo from '@/layouts/components/ColorLogo.vue'

export default {
  components: { ColorLogo },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: false,
      password: '',
      email: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      ValidationErrors: '',
    }
  },
  computed: {
    register() {
      return this.$store.getters['app/register']
    },
    ValidationPassword() {
      if (this.ValidationErrors.password) return this.ValidationErrors.password[0]
      return null
    },
    ValidationEmail() {
      if (this.ValidationErrors.email) return this.ValidationErrors.email[0]
      return null
    },
    appName() {
      // return $themeConfig.app.appName
      return this.$store.getters['app/title']
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  beforeCreate() {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
    this.$http.get('sanctum/csrf-cookie')
  },
  methods: {
    Login() {
      this.ValidationErrors = ''
      this.$store.dispatch('auth/LoginAction', {
        email: this.email,
        password: this.password,
        remember_me: this.status,
      }).then(() => {
        this.$router.push({
          name: 'home',
        })
      }).catch(error => {
        this.ValidationErrors = error.response.data.errors
      })
    },
    social_login(provider) {
      this.$store.dispatch('auth/SocialLoginAction', provider).then(response => {
        if (response.data.url) {
          window.location.href = response.data.url
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.divider{
  display: none;
}

.social{
  display: none !important;
}
</style>
